import React, { useEffect } from 'react';
import { SectionBase } from '~/components/domains/SectionBase';
import { textCss } from '~/styles/textCss';
import { stepperSteps } from '~/constants/stepperSteps';
import { linkStyle } from '~/styles/linkStyle';
import * as styles from './index.styles';
import { fireGAEvent } from '~/libs/fireGAEvent';
import { FlowType } from '~/types/flowType';

export type Props = {
  email: string;
  onClickBack: () => void;
  abTestName?: string;
  abTestPattern?: string;
  traceId?: string;
  flowType?: FlowType;
};

export const ConfirmEmailSection: React.FC<Props> = ({ email, onClickBack, ...gaParams }) => {
  useEffect(() => {
    fireGAEvent.customEvents.viewConfirmEmailSection(gaParams);
  }, []);

  return (
    <SectionBase
      currentStep={0}
      steps={stepperSteps}
      title="送信されたメールをご確認ください"
      centeringTitle
      descriptionIcon={'/assets/icon/send-email.svg'}
      sections={[
        {
          node: (
            <>
              <span
                css={textCss({
                  weight: 'bold',
                })}
              >
                supporter@timee.co.jp
              </span>{' '}
              から <span css={styles.email}>{email}</span> に認証用URLを送信しました。
              <br />
              メールが届かない場合は入力されたメールアドレスが間違っている可能性があります。
              <span css={linkStyle()} onClick={onClickBack}>
                こちら
              </span>
              から再度メールアドレスをご登録ください。
            </>
          ),
        },
      ]}
    />
  );
};
