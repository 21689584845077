import React from 'react';
import * as styles from './index.styles';

export type Props = React.PropsWithChildren<{
  className?: string;
}>;

export const ErrorMessage: React.FC<Props> = ({ className, children }) => {
  return (
    <p className={className} css={styles.errorMessage}>
      {children}
    </p>
  );
};
