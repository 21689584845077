import React from 'react';
import * as styles from './index.styles';

export type Props = React.PropsWithChildren<{
  href: string;
}>;

export const ExternalLink: React.FC<Props> = ({ href, children }) => (
  <a css={styles.link} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
