import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { colors } from '~/styles/colors';

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  max-width: 1120px;
  padding: 64px;
  background-color: ${colors.gray6};
  box-shadow: 0 4px 8px rgba(0 0 0 / 10%);
  border-radius: 8px;
`;

export const heading = css`
  ${textCss({
    size: 'l',
    weight: 'bold',
  })}
`;

export const note = css`
  line-height: 1.5;
`;

export const email = {
  src: css`
    ${textCss({
      weight: 'bold',
    })}
  `,
  dest: css`
    word-wrap: break-word;

    ${textCss({
      weight: 'bold',
      color: colors.accent,
    })}
  `,
};
