import { useRouter } from 'next/router';
import { FlowType } from '~/types/flowType';

type UseFlowType = () => { flowType: FlowType | undefined };

export const useFlowType: UseFlowType = () => {
  const router = useRouter();
  const flowType = router.query.flow_type as FlowType | undefined;
  return { flowType };
};
