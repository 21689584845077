import React from 'react';
import * as styles from './index.styles';

export type Props = {
  texts: React.ReactNode[];
};

export const InlineBreakText: React.FC<Props> = ({ texts }: Props) => (
  <>
    {texts.map((text, index) => (
      <span css={styles.text} key={`${index}-${text?.toString()}`}>
        {text}
      </span>
    ))}
  </>
);
