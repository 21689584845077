import React, { useCallback, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Image from 'next/image';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '~/components/buttons/Button';
import { ErrorMessage } from '~/components/data-displays/ErrorMessage';
import { Check } from '~/components/data-entries/Check';
import { Input } from '~/components/data-entries/Input';
import { Label } from '~/components/data-entries/Label';
import { InlineBreakText } from '~/components/typographies/InlineBreakText';
import { ErrorMessages } from '~/components/feedbacks/ErrorMessages';
import { fireGAEvent } from '~/libs/fireGAEvent';
import { ExternalLink } from './components/ExternalLink';
import * as styles from './index.tg.styles';
import { validateEmail } from '~/utils/validateEmail';
import { FlowType } from '~/types/flowType';

export type Props = {
  onClickRegister: (formData: Omit<FormValues, 'acceptedTerms'>) => void;
  sending: boolean;
  abTestName?: string;
  abTestPattern?: string;
  traceId?: string;
  flowType?: FlowType;
};

// NOTE: https://www.notion.so/timee/or-validate-7b3de6387e5645d08a74b51aaade3c3c#53e9743891de4720bc81049f09805937
const schema = z.object({
  selfEmployed: z.boolean({ required_error: '事業形態を選択してください。' }).default(false),
  email: z
    .string()
    .trim()
    .min(1, { message: 'メールアドレスを入力してください。' })
    .max(255, { message: '255文字以下で入力してください。' })
    .refine(validateEmail, { message: 'メールアドレスを正しい形式で入力してください。' }),
  acceptedTerms: z.literal<boolean>(true, {
    errorMap: () => ({
      message: 'アカウント開設には上記の各事項に同意が必要です。',
    }),
  }),
});

type FormValues = z.infer<typeof schema>;

export const TACAndInputEmailSection: React.FC<Props> = ({
  sending,
  onClickRegister,
  ...gaParams
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmitHandler = useCallback(
    handleSubmit((d) => {
      if (sending) return;
      onClickRegister({
        email: d.email,
        selfEmployed: d.selfEmployed,
      });
    }),
    [sending]
  );

  useEffect(() => {
    fireGAEvent.customEvents.viewTACAndInputEmailSection(gaParams);
  }, []);

  return (
    <div css={styles.container}>
      <div css={styles.introduction.container}>
        <p css={styles.introduction.catchphrase}>\ タイミーなら、今日の働き手がすぐ見つかる /</p>
        <p>
          <Image
            src="/images/timee-display.png"
            alt="Timeeの管理画面のイメージ"
            sizes="100vw"
            width="464"
            height="248"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </p>
        <p>
          <Image
            src="/images/timee-values.png"
            alt="Timeeを使うメリット"
            width="396"
            height="49"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </p>
        <p css={styles.introduction.text}>
          タイミーでは、簡単な業務からスキルを必要とする業務まで、難易度を問わず募集いただけます。
          <br />
          初期費用・掲載費用は無料、料金はワーカーが稼働した分だけ。無駄なコストをかけずに、人手を集めることができます。
        </p>
      </div>
      <hr css={styles.divider} />
      <form css={styles.form.container} onSubmit={onSubmitHandler} noValidate>
        <div css={styles.form.confirmations.container}>
          <p css={styles.form.confirmations.title}>申し込みの前に以下をご確認ください。</p>
          <ul css={styles.form.confirmations.list}>
            <li>
              <InlineBreakText
                texts={[
                  <>
                    「
                    <ExternalLink href="https://timee.co.jp/terms">
                      Timee事業者利用規約
                    </ExternalLink>
                    」
                  </>,
                  <>
                    「
                    <ExternalLink href="https://timee.co.jp/covenant">
                      Timee利用契約条項
                    </ExternalLink>
                    」
                  </>,
                  <>
                    「
                    <ExternalLink href="https://timee.co.jp/client-notice">
                      ご利用における留意事項
                    </ExternalLink>
                    」
                  </>,
                  'をご確認ください。',
                ]}
              />
            </li>
            <li>
              <InlineBreakText
                texts={[
                  'ご送信いただくメールアドレスその他の個人情報については、',
                  <>
                    「
                    <ExternalLink href="https://timee.co.jp/policy">
                      プライバシーポリシー
                    </ExternalLink>
                    」
                  </>,
                  'に従って利用させていただきます。',
                ]}
              />
            </li>
            <li>サービス利用料は日当と交通費の30％です。</li>
          </ul>
          <div>
            <Controller
              control={control}
              name="acceptedTerms"
              defaultValue={false}
              render={({ field, fieldState }) => (
                <Check
                  id="acceptedTerms"
                  label="上記の各事項の内容に同意する"
                  checked={field.value}
                  error={!!fieldState.error}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                />
              )}
            />
            <ErrorMessage
              css={[
                styles.schemaErrorMessage,
                errors.acceptedTerms?.message
                  ? styles.errorMessage.showed
                  : styles.errorMessage.hidden,
              ]}
            >
              {errors.acceptedTerms?.message}
            </ErrorMessage>
          </div>
        </div>
        <div>
          <Label label="登録するメールアドレス" htmlFor="email" labelBold>
            <Input type="text" id="email" error={!!errors?.email?.message} {...register('email')} />
            <ErrorMessage
              css={[
                styles.schemaErrorMessage,
                errors.email?.message ? styles.errorMessage.showed : styles.errorMessage.hidden,
              ]}
            >
              {errors.email?.message}
            </ErrorMessage>
          </Label>
        </div>
        <Button
          buttonHeight={48}
          fontSize="m"
          display="block"
          design="primary"
          type="submit"
          loading={sending}
        >
          アカウントの開設をはじめる
        </Button>
        <div css={styles.apiErrorMessages}>
          <ErrorMessages.WithStore />
        </div>
      </form>
    </div>
  );
};
