// 割合と識別用の値に応じてコンテンツのインデックスを返す
export const calcContentIndex = ({
  ratios,
  metric,
  shuffleTable = [0, 7, 2, 5, 8, 3, 6, 1, 4, 9],
}: {
  ratios: number[]; // 表示する割合（0 ~ 100）
  metric: number; // 識別用の値
  shuffleTable?: number[]; // 識別用の値（metric）の分散を図るためのシャッフルテーブル
}): number => {
  // 割合（ratio）の合計を計算し、100 でない場合はエラーを発生させる
  const total = ratios.reduce((acc, ratio) => acc + ratio, 0);
  if (total !== 100) {
    throw new Error('The sum of ratios must be 100.');
  }

  // 連続する metric の値を均等に分散させるための変換値を計算する
  // この計算により、metric の値が連続している場合でもインデックスが均等に分散されるようになる
  const normalizedMetric =
    (shuffleTable[metric % shuffleTable.length] * 10 + Math.floor(metric / shuffleTable.length)) %
    100;

  let cumulative = 0;
  return ratios.findIndex((ratio) => {
    cumulative += ratio;
    return normalizedMetric < cumulative;
  });
};
