import React from 'react';
import { useFlowType } from '~/hooks/common/useFlowType';
import { useGAUserPseudoId } from '~/hooks/common/useGAUserPseudoId';
import { useABTest } from '~/hooks/config/useABTest';
import * as styles from './index.styles';

import { applicationConfig } from '~/constants/applicationConfig';
import Image from 'next/image';

export type Props = React.PropsWithChildren<{}>;

const StartPageLayoutCG: React.FC<Props> = ({ children }) => (
  <div css={styles.outerContainer}>
    <div css={styles.container}>
      <div css={styles.logoTitle.container}>
        <Image width={256} height={56} src={'/assets/logo/with-icon.svg'} alt="タイミー" />
        <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
      </div>

      {children}
    </div>
  </div>
);

const StartPageLayoutTG: React.FC<Props> = ({ children }) => (
  <div css={styles.outerContainer}>
    <div css={styles.containerTG}>
      <div css={styles.logoTitle.container}>
        <Image width={256} height={56} src={'/assets/logo/with-icon.svg'} alt="タイミー" />
        <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
      </div>

      {children}
    </div>
  </div>
);

// NOTE: ABテスト終了までの暫定対応
// CGとTGとでレイアウトの幅が異なるため、致し方なくこのような実装になっている
export const StartPageLayout: React.FC<Props> = (props) => {
  const { flowType } = useFlowType();
  const { loading: loadingUserPseudoId, quantifiedUserPseudoId } = useGAUserPseudoId();
  const { renderABContent } = useABTest();

  const metric = flowType === 'self' ? quantifiedUserPseudoId : 0;

  if (loadingUserPseudoId) return null;

  return renderABContent(
    [
      { content: <StartPageLayoutCG {...props} />, ratio: 50 },
      { content: <StartPageLayoutTG {...props} />, ratio: 50 },
    ],
    metric
  );
};
