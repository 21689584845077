import React from 'react';
import { stepperSteps } from '~/constants/stepperSteps';
import { SectionBase } from '~/components/domains/SectionBase';

export type Props = {};

export const LoadingSection: React.FC<Props> = () => {
  return (
    <SectionBase
      steps={stepperSteps}
      currentStep={-1}
      descriptionIcon={'/assets/animation/loading-spinner.svg'}
      descriptionIconAlt="ロード中"
      title={''}
      sections={[
        {
          node: <div></div>,
        },
      ]}
    />
  );
};
