import { useCallback } from 'react';
import { calcContentIndex } from './helpers';

export type ContentRatio<T> = {
  content: T; // 表示するコンテンツ
  ratio: number; // 表示する割合（0 ~ 100）
};

export const useABTest = () => {
  const renderABContent = useCallback(<T>(contentRatios: ContentRatio<T>[], metric: number) => {
    const ratios = contentRatios.map((contentRatio) => contentRatio.ratio);
    const contentIndex = calcContentIndex({ ratios, metric });
    return contentRatios[contentIndex]?.content;
  }, []);

  return { renderABContent };
};
