import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { colors } from '~/styles/colors';
import { mediaQueries } from '~/styles/mediaQueries';

export const container = css`
  display: flex;
  align-items: center;
  gap: 32px;
  max-width: 1120px;
  padding: 64px;
  background-color: ${colors.gray6};
  box-shadow: 0 4px 8px rgba(0 0 0 / 10%);
  border-radius: 8px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    padding: 32px 16px;
    background-color: ${colors.white};
  }
`;

export const divider = css`
  display: none;

  ${mediaQueries.mobile} {
    display: block;
    width: 100%;
    border-top: 1px solid ${colors.gray6};
  }
`;

export const introduction = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;

    & > * + * {
      margin-top: 24px;
    }

    ${mediaQueries.mobile} {
      padding: 0;
    }
  `,
  catchphrase: css`
    ${textCss({ weight: 'bold' })}

    ${mediaQueries.mobile} {
      ${textCss({ weight: 'bold', size: 's' })}
    }
  `,
  text: css`
    line-height: 1.5;

    ${textCss({ size: 's' })}
  `,
};

export const form = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    padding: 48px 32px;
    background-color: ${colors.white};
    border-radius: 8px;

    & > * + * {
      margin-top: 16px;
    }

    ${mediaQueries.mobile} {
      padding: 0;
    }
  `,
  confirmations: {
    container: css`
      & > * + * {
        margin-top: 16px;
      }
    `,
    title: css`
      ${textCss({ weight: 'bold' })}
    `,
    list: css`
      line-height: 1.5;
      list-style: none;
      margin-left: 4px;
      padding-left: 0;

      ${textCss({ size: 's' })}

      & > li {
        position: relative;
        padding-left: 1.5em;

        &::before {
          content: '・';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    `,
  },
};

export const errorMessage = {
  hidden: css`
    visibility: hidden;
  `,
  showed: css`
    visibility: visible;
  `,
};

export const schemaErrorMessage = css`
  min-height: 14px;

  ${mediaQueries.mobile} {
    min-height: 28px;
  }
`;

export const apiErrorMessages = css`
  margin-top: 0;
  min-height: 42px;
`;
