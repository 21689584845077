import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const GA_COOKIE_KEY = '_ga';
export const POLING_INTERVAL = 100;
export const MAX_TRY_COUNT = 15;

type UseGAUserPseudoId = () =>
  | {
      loading: false;
      userPseudoId: string | null;
      quantifiedUserPseudoId: number;
    }
  | {
      loading: true;
      userPseudoId: null;
      quantifiedUserPseudoId: number;
    };

export const useGAUserPseudoId: UseGAUserPseudoId = () => {
  const [userPseudoId, setUserPseudoId] = useState<string | null>(null);
  const [tryCount, setTryCount] = useState(0);

  let gaCookie = Cookies.get(GA_COOKIE_KEY);

  useEffect(() => {
    if (gaCookie) {
      setUserPseudoId(extractUserPseudoIdFromGACookie(gaCookie));
      return;
    }

    // NOTE: Cookieの値が取得できない可能性があるためポーリングを行う
    const intervalId = setInterval(() => {
      setTryCount((prev) => prev + 1);

      gaCookie = Cookies.get(GA_COOKIE_KEY);
      if (gaCookie || tryCount >= MAX_TRY_COUNT) {
        clearInterval(intervalId);
        setUserPseudoId(gaCookie ? extractUserPseudoIdFromGACookie(gaCookie) : null);
      }
    }, POLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return userPseudoId || tryCount >= MAX_TRY_COUNT
    ? {
        loading: false,
        userPseudoId,
        quantifiedUserPseudoId: userPseudoId ? userPseudoIdToUniqueId(userPseudoId) : 0,
      }
    : {
        loading: true,
        userPseudoId: null,
        quantifiedUserPseudoId: 0,
      };
};

// 'GA1.2.1327899489.1734054500' -> '1327899489.1734054500'
function extractUserPseudoIdFromGACookie(gaCookie: string): string {
  return gaCookie.substring(6);
}

function userPseudoIdToUniqueId(userPseudoId: string): number {
  let hash = 0;
  for (let i = 0; i < userPseudoId.length; i++) {
    const char = userPseudoId.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash >>> 0;
}
