import React from 'react';
import { registerApiClient } from '~/apis/registerApiClient';
import { StartPageLayout } from '~/components/layouts/StartPageLayout';
import { InvalidParamsPage } from '~/components/pages/InvalidParamsPage';
import { StartPageV2 } from '~/components/pages/StartPageV2';
import { extractSalesforceVisitorIdFromCookie } from '~/libs/extractSalesforceVisitorIdFromCookie';
import { useSelfQueryValue } from '~/hooks/domain/useSelfQueryValue';

import { NextPageWithLayout } from '~/types/layout';

// https://www.notion.so/timee/DesignDoc-acd665cd3c7a4cde94100896b9978bba#3a2b61de2693474eb2378a104a7323af
const Page: NextPageWithLayout = () => {
  const queryValue = useSelfQueryValue();

  return (
    <>
      {queryValue.loaded && (
        <>
          {queryValue.flowType.valid ? (
            <StartPageV2
              apiClient={registerApiClient}
              flowType={queryValue.flowType.value}
              salesAgencyCode={queryValue.salesAgencyCode.value}
              salesOutsourceCode={queryValue.salesOutsourceCode.value}
              getSalesforceTrackingInfo={() => ({
                visitorId: extractSalesforceVisitorIdFromCookie(),
                landingUrl: location.href,
              })}
            />
          ) : (
            <InvalidParamsPage />
          )}
        </>
      )}
    </>
  );
};

Page.Layout = StartPageLayout;

Page.config = { pageTitle: 'メールアドレスの登録' };

export default Page;
