import { css } from '@emotion/react';
import { textStyle } from '~/styles/textStyle';
import { colors } from '~/styles/colors';

export const errorMessage = css`
  ${textStyle('s', 'bold')};

  color: ${colors.alert};
  margin-top: 8px;
  white-space: pre-wrap;
`;
